.db-settings-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;

  .db-settings-left-section {
    display: flex;
    width: 350px;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    padding: 10px 0;

    &.sidenav {
      display: block;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

      .sidenav-parent {
        list-style-type: none;
        padding: 0;
        margin: 0 0 0 0;

        li {
          font-size: 15px;
          color: #323232;
          padding: 10px 15px;

          .expand-collections{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .expand-icon{
              margin-right: 19px;
              font-size: large;
              cursor: pointer;
            }
            .expand-icon.expanded{
              transform: rotate(-180deg);
            }
          }

          & > a {
            display: block;
            color: #323232;
            padding: 10px 15px;
            margin: -10px -15px;

            &:hover, &.active {
              background-color: #d5d5d5;
            }
          }

          .sidenav-child.expand{
            display: block;
          }

          .sidenav-child {
            list-style-type: none;
            margin: 10px 0 0 0;
            padding: 0;
            display: none;

            li{
              >a {
                padding: 10px 30px;
                margin-left: -30px !important;
                margin-right: -30px !important;
                display: flex;
                justify-content: space-between;

                div:first-child{
                  width: 80%;
                  overflow: hidden;
                }

                div{
                  .config-badge{
                    border-radius: 50%;
                    border: 1px solid black;
                    display: flex;
                    // background-color: #eaeaea;
                    height: 15px;
                    width: 15px;
                    justify-content: center;
                    align-items: center;
                    font-size: smaller;
                  }
    
                  .config-badge.admin-configured{
                    // border-color: black;
                  }
                  .config-badge.user-configured{
                    border-color: black;
                    background-color: black;
                  }
                }
              }
            }
          }
        }
      }

      a {
        //padding: 8px 8px 8px 32px;
        text-decoration: none;
        //font-size: 25px;
        //color: #818181;
        //display: block;
        //transition: 0.3s;
        //
        //&:hover {
        //  color: #f1f1f1;
        //}
      }

      .close-btn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
      }
    }

    //ul {
    //  list-style-type: none;
    //  padding: 0 0 0 15px;
    //
    //  li {
    //    padding: 5px 0 5px 0;
    //
    //    a {
    //      text-decoration: none;
    //      cursor: pointer;
    //      color: #2d6ca2;
    //    }
    //  }
    //}
  }

  .db-settings-right-section {
    display: block;
    width: 100%;
    // background-color: #ffffff;
    padding: 10px 20px;

    .table-column-list {
      display: block;
      width: 100%;
      margin-top: 10px;

      .table-column-section-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        //align-items: center;
        margin-bottom: 10px;

        .table-column-section-wrapper-content {
          display: flex;
          flex: 1;

          .table-column-section {
            display: flex;
            flex: 1;
            flex-direction: column;

            .table-column-header-section {
              display: flex;
              flex: 1;
              align-items: center;
              background-color: #eaeaea;
              color: #414141;
              padding: 10px 20px;
              cursor: pointer;

              .table-column-title-wrapper {
                display: flex;
                flex: 1;
                //margin-left: 10px;

                .table-column-title {
                  display: flex;
                  flex: 1;
                  margin: 0;
                  padding: 0;
                }
              }

              .configured-tag {
                padding: 5px 15px;
                margin: 0 10px 0 0;
                font-size: 10px;
                background-color: rgb(76, 175, 80);
                color: #ffffff;
                border-radius: 15px;
              }
            }

            .table-column-content-section {
              background-color: #ffffff;
              border: 1px solid #eaeaea;
              padding: 15px 10px;

              .column-section-switch-wrapper {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
              }

              .column-section-label {
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 5px;
              }

              .column-section-information-icon {
                font-size: 14px;
                cursor: pointer;
                //margin-right: 10px;
              }

              .db-query-tooltip-section {
                max-width: 250px;
              }

              .db-input {
                width: 100%;
                margin-top: 6px;
              }
            }
          }
        }

        .table-column-section-wrapper-sort {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70px;

          .sort-up-icon-wrapper {
            overflow: hidden;

            .sort-up-icon {
              font-size: 30px;
              color: #474747;
              padding: 0;
              margin: 0 0 -13px 0;
              cursor: pointer;

              &.disabled {
                opacity: 0.2;
                cursor: no-drop;
              }
            }


            .sort-down-icon {
              position: relative;
              font-size: 30px;
              color: #474747;
              padding: 0;
              margin: -13px 0 0 0;
              cursor: pointer;
              z-index: 1;

              &.disabled {
                opacity: 0.2;
                cursor: no-drop;
              }
            }
          }

          .sort-order-div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-size: 12px;
            border: 1px solid gray;

            .sort-order-input {
              width: 30px;
              padding: 0;
              margin: 0;
              text-align: center;
              outline: none;
            }

            .sort-order-input::-webkit-inner-spin-button,
            .sort-order-input::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .empty-table-column-list {
      display: block;
      width: 100%;
      border: 1px solid #bebebe;

      .empty-text {
        font-size: 16px;
        text-align: center;
        padding: 0;
        margin: 60px 0;
      }
    }

    .header-serction{
      display: flex;
      flex-direction: row;

      .save-default-switch-wrapper {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        margin-left: 15px;
      }
  
      .save-default-label {
        font-size: 12px;
        font-weight: 500;
        // margin-bottom: 5px;
      }
    }
  }
}
