.field-filter-container {
  display: block;
  width: 100%;

  .field-filter-row-section {
    display: block;
    width: 100%;

    .field-filter-row {
      display: inline-block;
      width: 100%;

      .brace {
        font-size: 20px;
        color: #126E14;
      }

      .condition-text {
        font-size: 20px;
        color: blue;
      }

      .field-filter-row-inner {
        padding: 0 25px;
      }

      .delete {
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 8px;

        .delete-icon {
          width: 11px;
          height: 11px;
          color: #ffffff;
        }
      }

      .logic-select,
      .column-header-select {
        width: 200px;
        margin-right: 8px;
      }

      .condition-select,
      .filter-value-input {
        width: 150px;
        margin-right: 8px;
      }
    }
  }

  .field-filter-actions-section {
    display: inline-block;
    width: 100%;
  }
}

