.db-pre-aggregation-list {
  display: block;
  width: 100%;
  margin-bottom: 15px;

  .db-pre-aggregation-card {
    display: inline-block;
    width: 150px;
    text-align: center;
    border: 1px solid #c7c7c7;
    padding: 15px;
    margin-right: 15px;
    min-height: 45px;
    vertical-align: top;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);

    .db-pre-aggregation-card-label {
      padding: 0;
      margin: 0;
      //color: #eeeeee;
       color: #8e8e8e;
    }

    .db-pre-aggregation-card-value {
      padding: 0;
      margin: 0;
       color: #666666;
      //color: #fff;
    }

    .code-wrap {
      overflow-x: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      cursor: pointer;

      .code {
        padding: 0;
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        width: 10px;
        height: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b5b5b5;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: gray;
        width: 15px;
      }
    }

    //.code-wrap::-webkit-scrollbar {
    //  display: none;
    //}
  }
}
