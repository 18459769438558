.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 20px;
  //opacity: 0.5;
  //animation: fadeIn 500ms ease-in-out 500ms both;
  cursor: pointer;
  background-color: #3232325c;
  border-radius: 20px;

  &:hover {
    background-color: #323232ad;
  }

  .scroll-to-top-icon {
    font-size: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
