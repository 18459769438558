.db-modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);

  &.hide {
    display: none;
    pointer-events: none;
  }

  &.show {
    display: block;
    pointer-events: auto;
  }

  .db-modal-content {
    background-color: #fefefe;
    margin: 50px auto;
    border: 1px solid #888;
    width: 100%;
    //max-width: 650px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animateTop;
    animation-duration: 0.4s;

    .db-modal-header {
      padding: 20px;
      border-bottom: 1px solid #dee2e6;

      .db-modal-header-text {
        margin: 0;
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 30px;
        font-weight: bold;
        margin-top: -10px;

        &:hover {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }

        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .db-modal-body {
      padding: 20px;
    }
  }

}
