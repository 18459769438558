@import "src/global.scss";

// dtha
.dtha-section {
  padding-right: 0 !important;
  
  .dtha-aggregate-icon {
    font-size: 14px;
    color: rgba($table-header-color, 0.6);
    cursor: pointer;
  }

  .loading-section {
    min-height: 62.5px !important;
  }

  .dtha-aggregate-value {
    text-align: center;
  }

}
