.collection-tags-configuration-section {
	.selected-tags-list {
		display: block;
		width: 100%;

		.db-tag {
			margin: 0 5px;
		}
	}

	.add-tags-to-collection-section {
		margin-top: 20px;

		.save-button {
			width: 100px;
			height: 39px;
			margin: 0 !important;
		}
	}

	.db-tag {
		background-color: rgb(212, 212, 212);
		color: #000000;
		font-size: 12px;
		border-radius: 5px;
		padding: 5px 10px;

		.delete-icon {
			font-size: 12px;
			cursor: pointer;
			margin: 5px 0 0 7px;
		}
	}
}
