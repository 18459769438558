.pre-aggregate-config-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;

  .pre-aggregate-config-header-section {
    display: flex;
    flex: 1;
    align-items: center;
    background-color: #eaeaea;
    color: #414141;
    padding: 10px 20px;
    cursor: pointer;

    .pre-aggregate-config-title-wrapper {
      display: flex;
      flex: 1;
      //margin-left: 10px;

      .pre-aggregate-config-title {
        display: flex;
        flex: 1;
        margin: 0;
        padding: 0;
      }
    }
  }

  .pre-aggregate-config-content-section {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    padding: 15px 10px;

    .column-section-switch-wrapper {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }

    .column-section-label {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .column-section-information-icon {
      font-size: 14px;
      cursor: pointer;
    }

    .pre-aggregate-query-tooltip-section {
      max-width: 250px;
    }

    .db-input {
      width: 100%;
      margin-top: 6px;
    }
  }
}
