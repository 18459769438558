.login-div-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #e1e1e1;

  .login-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);

    .login-heading {
      padding: 0;
      margin: 10px 0 5px 0;
    }

    .login-sub-heading {
      font-size: 13px;
      padding: 0;
      margin: 0 0 15px 0;
    }

    .db-input {
      height: 35px !important;
      border-radius: 5px !important;
      margin-bottom: 10px !important;
      padding: 10px !important;
    }

    .login-actions-section {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      .login-actions-section-left {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;

        .login-button {
          height: 35px !important;
          width: 100px !important;
        }
      }

      .login-actions-section-right {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .fp-link {
          color: #323232;
          text-decoration: none;
        }
      }
    }
  }
}
