$color-white: #ffffff;
$color-black: #000000;
$color-gray-extra-light: #f2f2f2;
$color-gray-light: #eeeeee;
$color-gray-regular: #dddddd;
$color-gray-medium: #d7d7d7;
$color-gray-semi-dark: #7b7b7b;
$color-gray-dark: #5a5a5a;

$primary: #2c3d63;
$primary-alt: #ec6927;
$primary-dark: #2c3d63;
$secondary: #2c3d63;
$secondary-light: #b1bace;
$link-color: #1890ff;

// Status
$success-color: #4caf50;
$error-color: #d00909;
$warning-color: #ff9502;
$information-color: #0dcaf0;

// Background
$body-background-color: #e5e5e5;
$component-background: #ffffff;

// Text
$text-color: rgba($color-black, 0.8);
$text-color-secondary: rgba($color-black, 0.4);
$text-color-inverse: $color-white;

// Input
$input-border-radius: 5px;

// Select
$select-border-radius: 5px;

// Buttons
$btn-border-radius: 5px;

$btn-default-color: rgba(0, 0, 0, .8);;
$btn-default-background-color: #ffffff;
$btn-default-border-color: rgb(217, 217, 217);

$btn-primary-color: #ffffff;
$btn-primary-background-color: $primary;
$btn-primary-border-color: $primary;

$btn-success-color: #fff;
$btn-success-background-color: $success-color;
$btn-success-border-color: $success-color;

$btn-error-color: #fff;
$btn-error-background-color: $error-color;
$btn-error-border-color: $error-color;

$btn-warning-color: #fff;
$btn-warning-background-color: $warning-color;
$btn-warning-border-color: $warning-color;

$btn-information-color: #fff;
$btn-information-background-color: $information-color;
$btn-information-border-color: $information-color;

$btn-danger-color: #fff;
$btn-danger-background-color: $error-color;
$btn-danger-border-color: $error-color;

// Table

$table-background-color: $color-white;
$table-odd-row-background-color: rgba(0, 0, 0, 0.03);
$table-even-row-background-color: $color-white;
$table-header-background-color: $secondary-light;
$table-header-color: $primary-dark;

$table-header-hover-background-color: $secondary-light;
$table-header-hover-color: $primary-dark;

@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex !important;
}

@mixin flex-wrap($value: nowrap) {
	-webkit-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}

@mixin flex-grow($int: 1) {
	-webkit-box-flex: $int;
	-moz-box-flex: $int;
	-webkit-flex-grow: $int;
	-ms-flex: $int;
	flex-grow: $int;
}

@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-moz-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-moz-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-moz-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-moz-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	align-items: $value;
}

@mixin customScrollBar {
	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: rgba(88, 38, 38, 0.12);
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $secondary;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-button:single-button {
		display: block;
		border-style: solid;
		height: 5px;
		width: 10px;
	}
	&::-webkit-scrollbar-button:single-button:vertical:decrement {
		border-width: 0 5px 5px 5px;
		border-color: transparent transparent $secondary transparent;
	}
	&::-webkit-scrollbar-button:single-button:vertical:increment {
		border-width: 5px 5px 0 5px;
		border-color: $secondary transparent transparent transparent;
	}
	&::-webkit-scrollbar-button:single-button:horizontal:decrement {
		border-width: 5px 5px 5px 0px;
		border-color: transparent $secondary transparent transparent;
	}
	&::-webkit-scrollbar-button:single-button:horizontal:increment {
		border-width: 5px 0 5px 5px;
		border-color: transparent transparent transparent $secondary;
	}
}

// Mixin Function for paddings and margins
$max: 100;
$offset: 1;
$unit: 'rem';
@mixin spacing-loop($className, $styleName) {
	$i: 0;
	@while $i <= $max {
		#{$className + $i} {
			#{$styleName}: #{$i/16 + $unit} !important;
		}
		$i: $i + $offset;
	}
}

// Margins
@include spacing-loop('.mt-', 'margin-top');
@include spacing-loop('.mr-', 'margin-right');
@include spacing-loop('.mb-', 'margin-bottom');
@include spacing-loop('.ml-', 'margin-left');
@include spacing-loop('.mx-', 'margin');

// Paddings
@include spacing-loop('.pt-', 'padding-top');
@include spacing-loop('.pr-', 'padding-right');
@include spacing-loop('.pb-', 'padding-bottom');
@include spacing-loop('.pl-', 'padding-left');
@include spacing-loop('.px-', 'padding');

// Common Classes and CSS
.mat-shadow-1 {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mat-shadow-2 {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mat-shadow-3 {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.mat-shadow-4 {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.mat-shadow-5 {
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;
}

.pointer,
.material-icons,
.MuiSvgIcon-root {
	cursor: pointer;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.nowrap {
	white-space: nowrap;
}

.white-color {
	color: #fff !important;
}

.primary {
	color: $primary !important;
}

.dark-grey {
	color: $color-gray-dark !important;
}

.semi-dark-grey {
	color: $color-gray-semi-dark !important;
}

.error-color {
	color: $error-color !important;
}

.success-color {
	color: $success-color !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.customScrollBar {
	@include customScrollBar;
}

.noBorder {
	border: 0px none !important;
}

.minw-200 {
	min-width: 200px !important;
}

.maxw-150 {
	max-width: 150px !important;
}

.maxw-200 {
	max-width: 200px !important;
}
.maxw-100 {
	max-width: 100px !important;
}

.w-100 {
	width: 100% !important;
}

.w-200 {
	width: 200px;
}

.w-50 {
	width: 50%;
}

.flex {
	@include flexbox();
}

.fg-1 {
	@include flex-grow((1));
}

.ai-center {
	@include align-items(center);
}

.ai-flex-start {
	@include align-items(flex-start);
}

.ai-flex-end {
	@include align-items(flex-end);
}

.jc-center {
	@include justify-content(center);
}

.jc-space-between {
	@include justify-content(space-between);
}

.jc-space-evenly {
	@include justify-content(space-evenly);
}

.jc-flex-end {
	@include justify-content(flex-end);
}

.jc-space-around {
	@include justify-content(space-around);
}
