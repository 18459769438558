@import "src/global.scss";

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.dt-container {
  display: block;
  width: 100%;
  position: relative;
  background-color: $table-background-color;
}

.dt-loading-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  z-index: 7;

  p {
    font-size: 18px;
    color: #fafafa;
    text-align: center;
  }
}

.dt-wrapper {
  display: block;
  width: 100%;
  overflow: auto;
}

.dt-wrapper.fixed-table-header .dt > .dt-thead > .dt-tr > .dt-th {
  position: sticky;
  top: 0;
}

.dt {
  display: table;
  table-layout: fixed;
  //border-collapse: separate;
  width: 100%;
  min-height: 400px;
  //position: relative;
  //border-spacing: 0;
  //text-align: left;
  //box-sizing: border-box;
  //-webkit-box-sizing: border-box;
  .dt-col {
    display: table-column;
  }

  .dt-thead {
    display: table-header-group;
  }

  .dt-tbody {
    display: table-row-group;
  }

  .dt-tr {
    display: table-row;
  }

  .dt-tr:nth-child(odd) {
		background-color: $table-odd-row-background-color;
	}

	.dt-tr:nth-child(even) {
		background-color: $table-even-row-background-color;
	}

  .dt-th {
    display: table-cell;
  }

  .dt-td {
    display: table-cell;

    &.loading {
      position: relative;

      .bar {
        background-color: #E7E7E7;
        height: 25px;
        //border-radius: 7px;
        width: 100%;
      }

      &:after {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 24px;
        background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%);
        background-size: 200px 24px;
        background-position: -100px 0;
        background-repeat: no-repeat;
        animation: loading 1s infinite;
      }
    }
  }

  > .dt-thead {
    > .dt-tr {
      > .dt-th {
        text-align: left;
        overflow-wrap: break-word;
        border: 1px solid #f0f0f0;
        font-weight: unset !important;
        background-color: $table-header-background-color;
        color: $table-header-color;
        z-index: 1;

        .dt-header-cell-inner {
          font-weight: 500;
        }
      }

      > .dt-th.sortable {
        &:hover {
          background-color: #0c5b7c;
          cursor: pointer;
        }
      }

      > .dt-th.fixed {
        position: sticky;
        z-index: 5 !important;
      }

      > .dt-th.fixed.active-filter {
        z-index: 6 !important;
      }

      > .dt-th.active-filter {
        z-index: 2;
      }
    }
  }

  > .dt-tbody{
    > .dt-tr {
      > .dt-td {
        // background-color: #ffffff;
        // text-align: left;
        padding: 15px;
        border: 1px solid #f0f0f0;
      }

      .dt-td.word-break {
        word-break: break-word;
      }

      > .dt-td.link {
        color: $link-color;
        cursor: pointer;
      }

      > .dt-td.wrap-dynamic {
        overflow-wrap: break-word;
      }

      > .dt-td.wrap-scroll-x {
        white-space: nowrap;
        overflow-x: auto;

        //&::-webkit-scrollbar {
        //  width: 7px;
        //  height: 7px;
        //}
        //
        ///* Track */
        //&::-webkit-scrollbar-track {
        //  box-shadow: inset 0 0 5px grey;
        //  border-radius: 10px;
        //}
        //
        ///* Handle */
        //&::-webkit-scrollbar-thumb {
        //  background: #b5b5b5;
        //  border-radius: 10px;
        //}
        //
        ///* Handle on hover */
        //&::-webkit-scrollbar-thumb:hover {
        //  background: gray;
        //}
      }

      > .dt-td.wrap-scroll-y {
        height: 100%;
        overflow-y: auto;

        .wrap-scroll-y-inner {
          display: block;
          width: 100%;
          max-height: 40px;
          overflow-y: auto;
          word-break: break-all;

          //&::-webkit-scrollbar {
          //  width: 7px;
          //  height: 7px;
          //}
          //
          ///* Track */
          //&::-webkit-scrollbar-track {
          //  box-shadow: inset 0 0 5px grey;
          //  border-radius: 10px;
          //}
          //
          ///* Handle */
          //&::-webkit-scrollbar-thumb {
          //  background: #b5b5b5;
          //  border-radius: 10px;
          //}
          //
          ///* Handle on hover */
          //&::-webkit-scrollbar-thumb:hover {
          //  background: gray;
          //}
        }
      }

      > .dt-td.clip {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .dt-td.dynamic {
        white-space: nowrap;
      }

      > .dt-td.fixed {
        position: sticky;
        z-index: 4;
      }
    }
  }

  >.dt-tbody[isEmpty="empty"]{
    display: inline-block;
    .dt-tbody-empty {
      display: block;
      width: 100%;
      height: 350px;
      text-align: center;
      position: absolute;
      z-index: 1;

      .no-data-image {
        width: 150px;
        margin-top: 60px;
      }

      .no-data-text {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.25);
        margin: 10px 0 0 0;
      }
    }
  }

  .dt-header-cell {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    .dt-header-cell-inner {
      display: flex;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 15px;
    }

    .dt-header-cell-inner.sortable {
      justify-content: space-between;
      padding-right: 0px;
      cursor: pointer;

      &:hover {
        background-color: $table-header-hover-background-color;
				color: $table-header-hover-color;
      }

      > span:first-child {
        overflow-x: hidden;
      }
    }
  }

  .dt-sorter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    text-transform: none;
    margin-left: 6px;
    margin-right: 6px;

    .dt-sort-up, .dt-sort-down {
      font-size: 14px;
      color: rgba($table-header-color, 0.6);

      &.active {
        color: $table-header-color;
      }
    }

    .dt-sort-up {
      margin-bottom: -6px;
    }

    .dt-sort-down {
      margin-top: -6px;
    }
  }

  .dt-filter-icon {
    font-size: 14px;
    color: rgba($table-header-color, 0.6);
    cursor: pointer;

    &.active {
      color: $table-header-color;
    }
  }
}

.dt-sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
}

.dt-custom-scrollbar-wrapper {
  width: 100%;
  overflow-x: auto;
  //position: sticky;
  //bottom: 0;
  //z-index: 5;
}

.dt-custom-scrollbar {
  height: 15px;
}

.dt-pagination-container {
  display: flex;
  flex: 1;
  padding: 10px;
  background-color: #fafafa;
  border: 0;
  //position: sticky;
  //bottom: 0;
  //z-index: 5;

  .dt-pagination-container-left {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .dt-pagination-container-right {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  p {
    font-size: 12px;
    color: gray;
    padding: 0;
    margin: 5px 0;
  }

  .dt-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 8px 0;

    > li {
      display: inline-block;
      cursor: pointer;
      list-style: none;
      outline: 0;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      vertical-align: middle;

      > a {
        &:hover {
          border: 1px solid #40a9ff !important;
          color: #40a9ff !important;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .dt-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-next-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-prev-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-next-page.disabled {
      a {
        cursor: not-allowed;
        border: 1px solid #d9d9d9 !important;
        color: #d9d9d9 !important;
      }
    }

    .dt-prev-page.disabled {
      a {
        cursor: not-allowed;
        border: 1px solid #d9d9d9 !important;
        color: #d9d9d9 !important;
      }
    }

    > li.active {
      a {
        border: 1px solid #40a9ff !important;
        color: #40a9ff !important;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);
  z-index: 100;
  //transform: translateX(15px);
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.db-draggable {
  position: absolute;
  z-index: 4;
}

div.dt-th:has(div.dt-header-cell > div.dropdown.active) {
  background-color: #000 !important;
}
