.pnf-section {
  display: block;
  margin-top: 50px;

  .pnf-image {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .pnf-sub-title {
    font-size: 25px;
    font-style: italic;
    color: #323232;
    margin: 0;
    padding: 0;
  }
}
