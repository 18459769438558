.user-restrictions-section {
  display: block;
  width: 100%;
  margin-top: 10px;

  .admin-configuration-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .collection-level-restriction-row {
    padding: 15px;
    border: 1px solid gray;
    border-bottom: none;

    &:last-child {
      border: 1px solid gray !important;
    }
  }

  .react-selectcomponent{
    display: inline-block;
    width: 250px;
    margin: 0;
  }

  .edit-button, button {
    margin-top: 0;
    margin-bottom: 0;
    height: 35px;
  }

  .save-button{
    width: 100px !important;
  }
}
