@import './helpers/scss-variables.scss';
@import './global.scss';

html,
body {
	height: 100%;
	margin: 0;
	background-color: $body-background-color;
}

a {
	color: $link-color;
}

.anchor-link{
	color: $link-color !important;
	cursor: pointer;
}

.error-link{
	color: $error-color !important;
	cursor: pointer;

	.error-icon{
		font-size: 12px;
	}
}

.db {
	display: block;

	.code {
		background-color: #eee;
		border-radius: 3px;
		font-family: courier, monospace;
		padding: 6px;
	}
}

.db-top-heading {
	background-color: $primary;
	color: #fafafa;
	padding: 16px;
	margin: 0;
}

.db-main-content {
	display: block;
	padding: 16px;
}

.db-show-hide-filter-section {
	display: block;
	width: 100%;
	// margin-bottom: 10px;

	.db-show-hide-filter-button {
		width: 130px;
		margin-right: 10px;
	}
}

.db-button {
	font-size: 12px;
	color: $btn-default-color;
	border: 1px solid $btn-default-border-color;
	background-color: $btn-default-background-color;
	text-decoration: none;
	min-height: 30px;
	padding: 7px 20px;
	margin: 10px 0;
	border-radius: $btn-border-radius;
	cursor: pointer;

	&:hover {
		outline: 0;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		opacity: 0.8;
	}
}

.db-button.db-button--primary {
	background-color: $btn-primary-background-color;
	color: $btn-primary-color;
}

.db-button.db-button--danger {
	background-color: rgb(202, 60, 60);
	color: #ffffff;
}

.db-button.db-button--success {
	background-color: rgb(28, 184, 65);
	color: #ffffff;
}

.db-input:not(.db-input[type='checkbox'], .db-input[type='radio']),
.db-select {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	min-height: 30px;
	border: 1px solid #c2c2c2;
	border-radius: $input-border-radius;
	padding: 5px;
	margin-bottom: 5px;
	outline: 0;
}

.db-input[type='checkbox'],
.db-input[type='radio'] {
	border-color: #c2c2c2;
}

.db-input[type='checkbox'],
.db-input[type='radio'] {
	margin-left: 0;
	cursor: pointer;
}

.db-input:disabled,
.db-select:disabled {
	cursor: no-drop;
	color: rgb(170, 170, 170) !important;
	background-color: rgb(238, 238, 235) !important;
	opacity: 1 !important;
}

.db-input-group-wrapper {
	display: inline-block;
	width: auto;

	.db-input-group {
		display: flex;
		align-items: center;

		.db-input-group-prepend {
			display: flex;
			height: 100%;
			border: 1px solid $btn-default-border-color;
			background-color: $btn-default-background-color;
			padding: 6px 15px;
			font-size: 12px;
			border-radius: $input-border-radius 0 0 $input-border-radius !important;
			cursor: default;
		}

		input.db-input {
			display: flex;
			height: 28px !important;
			margin: 0 !important;
			border-right: none !important;
			border-radius: 0 !important;
		}

		select.db-select {
			display: flex;
			height: 28px !important;
			//padding: 5px;
			margin: 0 !important;
			border-radius: 0 $input-border-radius $input-border-radius 0 !important;
			cursor: pointer;
		}
	}
}

.divider {
	display: flex;

	&:before {
		content: '';
		flex: 1;
	}

	&:after {
		content: '';
		flex: 1;
	}
}

.line {
	align-items: center;
	margin: 10px 0;

	&:before {
		height: 1px;
		margin: 0 1em;
	}

	&:after {
		height: 1px;
		margin: 0 1em;
	}
}

.razor {
	&:before {
		box-shadow: 0 0.5px 0 black;
	}

	&:after {
		box-shadow: 0 0.5px 0 black;
	}
}

.full-width {
	width: 100%;
}

.mw-20 {
	width: 20px;
}

.w-50 {
	width: 50px;
}

.w-100 {
	width: 100px;
}

.m-0 {
	margin: 0;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-5 {
	margin-top: 5px;
}

.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-15 {
	margin-left: 15px;
}

.mt-6 {
	margin-top: 6px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-100 {
	margin-top: 100px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 50px;
}

.p-0 {
	padding: 0;
}

.db-alert {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px 16px;
	font-size: 14px;
	font-weight: 400;
	border-radius: 4px;
	letter-spacing: 0.01071em;
	opacity: 1;
	-webkit-transition: opacity 1000ms linear;
	transition: opacity 1000ms linear;

	.db-alert-icon-section {
		display: flex;
		margin-right: 5px;

		.bell-icon {
			margin-top: 3px;
		}
	}

	.db-alert-content-section {
		display: flex;
		flex: 1;
		flex-direction: column;

		ul {
			padding-left: 40px;
		}
	}

	p {
		padding: 0;
		margin: 0 0 0 10px;
	}

	&.success {
		color: #000000;
		background-color: #d1e7dd;
	}

	&.warning {
		color: #000000;
		background-color: #fff4e5;
	}
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.strike-text {
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	border-bottom: 0.5px solid #545454;
	line-height: 3.5px;
	margin: 20px 0 10px 0;

	span {
		background: #fff;
		padding: 0 10px 0 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.skeleton {
	.bar {
		background-color: #e7e7e7;
		height: 25px;
		//border-radius: 7px;
		width: 100%;
	}

	&:after {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		content: '';
		display: block;
		width: 100%;
		height: 24px;
		background-image: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.5) 60%,
			rgba(255, 255, 255, 0) 80%
		);
		background-size: 200px 24px;
		background-position: -100px 0;
		background-repeat: no-repeat;
		animation: loading 1s infinite;
	}
}

.custom-tabs {
	.react-tabs__tab-list {
		margin: 0 !important;
		border: none !important;
		.react-tabs__tab--selected {
			border: none !important;
		}
		.react-tabs__tab:focus {
			box-shadow: none !important;
			border: none !important;
		}
	}

	.react-tabs__tab-panel {
		background-color: #ffffff;
		// border: 1px solid #aaa;
		border-top: none !important;
		padding: 5px 0;
	}
}

.filter-tabs {

	&.json-filter__active {
		.react-tabs__tab-panel {
			background-color: #ffffff;
			padding-top: 10px !important;
		}
	}

	.react-tabs__tab-list {
		margin-bottom: 0;
	}
	.react-tabs__tab-panel {
		background-color: #ffffff;
		padding: 0 10px;
	}
	.react-tabs__tab-list {
		border: none !important;
	}
	.react-tabs__tab--selected {
		border: none !important;
	}
}


.database-icon {
	// font-size: 15px;
	color: #1890ff;
	margin-right: 5px;
}
.tags-icon {
	font-size: 15px;
	// color: #F45681;
	// color: #FF3E7E;
	margin-right: 5px;
}

.db-pre-aggregation-card.threshold-between {
	background-color: #fc930a;
}

.db-pre-aggregation-card.threshold-equals {
	background-color: #18bdd1;
}

.db-pre-aggregation-card.threshold-hcl {
	background-color: #4da851;
}

.db-pre-aggregation-card.threshold-lcl {
	background-color: #e8423e;
}

.db-pre-aggregation-card.threshold-multivalue {
	// background-color: #9c27b0;
}

.db-pre-aggregation-card.threshold-hcl,
.db-pre-aggregation-card.threshold-lcl,
.db-pre-aggregation-card.threshold-equals,
.db-pre-aggregation-card.threshold-between {
	.db-pre-aggregation-card-label,
	.db-pre-aggregation-card-value {
		color: #ffffff;
	}
}

.alert-modal-section {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	.error-icon{
		font-size: 40px;
		color: $error-color;
		margin-right: 20px;
	}

	.alert-modal-content-section{
		display: flex;
		flex: 1;
		flex-direction: column;

		h3, p {
			padding: 0;
			margin: 0;
		}

		.error-detail-link{
			font-size: 13px;
			color: $link-color;
			cursor: pointer;
			margin-top: 5px;
		}

		.alert-modal-content-detailed-section{
			border-left: 4px solid gray;
			padding: 7px;
			margin-top: 5px;
		}

		ul {
			margin: 0;
			padding-left: 20px;
		}
	}
}

.ace-editor-wrapper {
	position: relative;

	.ace-editor-fs-action {
		font-size: 20px;
		color: #ffffff;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		cursor: pointer;
		background-color: #717171;
		padding: 10px;
		border-bottom-left-radius: 10px;
	}
}